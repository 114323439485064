<template lang="pug">
    base-page
        template(v-slot:titlu)
            span Raport totalizator
        template(v-slot:leftmenu)
            el-tooltip(content="Export excel")
                a(class='el-button el-tooltip el-button--success el-button--small is-circle' target='_blank' :href="get_excel_href()") 
                    i.el-icon-document
            //-
            //- el-tooltip(content="Export condica")
            //-     a(class='el-button el-tooltip el-button--warning el-button--small is-circle' target='_blank' :href="get_excel_href_condica()") 
            //-         i.el-icon-document
            //- el-tooltip(content="Export nou")
            //-     a(class='el-button el-tooltip el-button--danger el-button--small is-circle' target='_blank' :href="get_excel_href_condica2()") 
            //-         i.el-icon-document 
            //- el-tooltip(content="Export condica cu rotunjire")
            //-     a(class='el-button el-tooltip el-button--primary el-button--small is-circle' target='_blank' :href="get_excel_href_condica_rotunjit()") 
            //-         i.el-icon-document
            el-tooltip(content="Export condica cu rotunjire si ore de noapte")
                a(class='el-button el-tooltip el-button--info el-button--small is-circle' target='_blank' :href="get_excel_href_condica_rotunjit_cu_noapte()") 
                    i.el-icon-document

        el-card(id="filtre")
            el-row( :gutter="20" )
                el-col(:span="4" :xs="24")
                    el-date-picker.full-width( v-model="Filters.Data" type="month" placeholder="Alege o luna" format="MMM yyyy" value-format='MM-yyyy' size='mini')
                el-col(:span="20" :xs="24")
                    el-checkbox-group( v-model="Filters.DepartamenteBifate")
                        el-checkbox( v-for="d in Info.Departamente" :label="d.Id" :key="'d-' + d.Id") {{d.Nume}}
                    span Angajati prezenti astazi: {{ get_nr_oameni_prezenti_astazi() }}

                el-col(:span="12" :xs="12")
                    el-button.btn-aplica(type='primary' @click='refresh_info()' size='mini') Aplica

        div.raport-container(id="raport-container" v-loading="loadingVisible")
            span(v-if="selectedEmployeeId !== null") Angajat selectat: {{ selectedEmployeeId }}
            table.raport-table( border='0' )
                thead
                    tr
                        th.cell-angajat Angajat
                        th( v-for="zi in Raport.Zile" :key="'zi-'+ zi.Day" align="center" 
                            :class="{ 'cell-data': true, 'weekend' : zi.Weekend, 'zi-libera-national': zi.ZiLiberaNational, 'zi-libera': zi.ZiLibera, 'alb': true } ") 
                            span {{zi.Day}}
                        th.cell-totaluri( v-for='tzl in Raport.TipuriZileLibere' ) 
                            el-tooltip(:content="tzl.Nume" placement="top") 
                                div {{tzl.Prescurtare}}
                        th.cell-total-ore-lucrate
                            el-tooltip(content="Necesar Ore Lucrate" placement="top") 
                                div NOL
                        th.cell-total-ore-lucrate
                            el-tooltip(content="Total Ore Lucrate" placement="top") 
                                div TOL
                        th.cell-totaluri
                            el-tooltip(content="Ore Suplimentare" placement="top") 
                                div OS
                        th.cell-totaluri
                            el-tooltip(content="Salariu Net" placement="top") 
                                div SN
                        th.cell-totaluri
                            el-tooltip(content="Ore Noapte" placement="top") 
                                div ON
                        th.cell-totaluri
                            el-tooltip(content="Abatere" placement="top") 
                                div Abatere (-)
                        th.cell-totaluri
                            el-tooltip(content="Zile sarbatori legale" placement="top") 
                                div ZSL
                        th.cell-totaluri
                            el-tooltip(content="Bonuri de masa" placement="top") 
                                div BON
                        th Detalii
                        //- th.cell-observatii-lunare Observatii
                tbody
                    tr.rand-angajat(:class="{ 'angajat-selectat': selectedEmployeeId === idxa+1 }" v-for="(a,idxa) in Raport.Angajati" :key="a.Id")

                        //- td.nume-angajat(:class="{'este-angajat-nou': verificaAngajatNou(a)}" :style="{ display: 'flex', 'align-items': 'center' }")
                        //-     el-radio(v-model="selectedEmployeeId" :label="idxa+1  " :value="a.Id")
                        //-     el-tooltip(:content="a.Nume")
                        //-         el-button.btn-nume-angajat(type='text' @click='show_angajat_dlg(a)' :style="{ 'text-align': 'left', 'justify-content': 'flex-start' }") {{a.Nume}}

                        td.nume-angajat(:class="{'este-angajat-nou': verificaAngajatNou(a)}" :style="{ display: 'flex', 'align-items': 'center' }")
                            el-radio(v-model="selectedEmployeeId" :label="idxa+1  " :value="a.Id" )
                            el-tooltip(:content="a.Nume")
                                el-button.btn-nume-angajat(type='text' @click='show_angajat_dlg(a)' :style="{ 'text-align': 'left', 'justify-content': 'flex-start' }") {{a.Nume}}



                   

                        td( v-for="(zi,idxz) in a.Zile" :key="'zi-'+ idxz +'-' + a.Id" align="center" 

                        :class="{ 'day': true, 'weekend' : zi.Weekend, 'zi-libera-national': zi.ZiLiberaNational, 'zi-libera': zi.ZiLibera, 'sters' : verificaSters(zi, a), 'a-fost-absent': zi.AFostAbsent, 'zi-cu-eroare' : zi.Erori != ''}") 
                            div(v-if='!zi.ZiLibera')
                                div( v-if="zi.EsteAstazi && zi.EstePrezentAstazi")
                                    el-tooltip(content="Prezent astazi")
                                        el-button(type='text' class='green-text-button' @click='arata_dialog_modificare_timp(a,zi)') 
                                            span(v-if="!verificaSters(zi, a)" :class="{ 'blinker': zi.TimpLucrat > 43200 }") {{zi.TimpLucrat|HHMM}}
                                            span(v-else) X
                                div(v-else)
                                    el-tooltip( v-if="tooltipForDay(a,zi) !=''" :content="tooltipForDay(a,zi)")
                                        el-button(type='text' @click='arata_dialog_modificare_timp(a,zi)') 
                                            span(v-if="!verificaSters(zi, a)" :class="{ 'blinker': zi.TimpLucrat > 43200 }") {{zi.TimpLucrat|HHMM}}
                                            span(v-else) X
                                    div( v-else )
                                        el-button(type='text' @click='arata_dialog_modificare_timp(a,zi)') 
                                            span(v-if="!verificaSters(zi, a)" :class="{ 'blinker': zi.TimpLucrat > 43200 }") {{zi.TimpLucrat|HHMM}}
                                            span(v-else) X
                            div(v-else :style="{ backgroundColor: zi.ZiLiberaInfo.Culoare}") 
                                el-tooltip(:content="zi.ZiLiberaInfo.Nume" placement="top") 
                                    div {{zi.ZiLiberaInfo.Prescurtare}}

                        td.cell-totaluri( v-for='tzl in Raport.TipuriZileLibere' )
                            div {{a.TotaluriZileLibere[ tzl.Prescurtare ]}}
                        td(class='cell-total-ore-lucrate force-white-color') {{a.NecesarOreLucrate|HHMM}}
                        td(class='cell-total-ore-lucrate force-white-color') {{a.TotalOreLucrate|HHMM}}
                        td.cell-totaluri {{a.OreSuplimentare|HHMM}}
                        td.cell-totaluri {{a.SalariuNet}}
                        td.cell-totaluri {{a.TotalOreNoapte|HHMM}}
                        td.cell-totaluri {{a.Abatere|HHMM}}
                        td.cell-totaluri {{a.ZSL|HHMM}}
                        td.cell-totaluri {{a.NrBonuriMasa}}
                        td.cell-detalii  {{a.DetaliiRaport}}
                        //- td
                        //-     el-row
                        //-         el-col( :span=20 )
                        //-             input( type='text' :class="{ 'observatii-input': true, 'dirty': a.IsObservatiiDirty }" v-model='a.Observatii' @keyup="a.IsObservatiiDirty=true" )
                        //-         el-col( :span=4 )
                        //-             el-tooltip( content="Salveaza" placement="top")
                        //-                 el-button(type="success" icon="el-icon-check" circle size='mini' @click='salveaza_observatii(a)')
                tfoot
                    tr
                        td.cell-total Angajati prezenti
                        td( v-for="zi in Raport.Zile" :class="{ 'cell-data': true, 'weekend' : zi.Weekend, 'zi-libera-national': zi.ZiLiberaNational, 'zi-libera': zi.ZiLibera } ") 
                            span {{total_angajati_prezenti(zi)}}
                        td( v-for="i in 25") -
                    tr
                        td.cell-total TOTAL
                        td( v-for="zi in Raport.Zile" :class="{ 'cell-data': true, 'weekend' : zi.Weekend, 'zi-libera-national': zi.ZiLiberaNational, 'zi-libera': zi.ZiLibera } ") 
                            span {{total_pe_zi(zi)|HHMM}}
                        td.cell-totaluri( v-for='tzl in Raport.TipuriZileLibere' ) 
                            span {{total_pe_tip_zi_libera(tzl)}}
                        td.cell-necesar-ore-lucrate
                            span {{total_necesar_ore_lucrate()|HHMM}}
                        td.cell-total-ore-lucrate
                            span {{total_ore_lucrate_de_toti()|HHMM}}
                        td.cell-ore-suplimentare
                            span {{total_ore_suplimentare()|HHMM}}
                        td.cell-ore-noapte
                            span {{total_ore_noapte()|HHMM}}
                        td.cell-abateri
                            span {{total_abateri()|HHMM}}
                        td.cell-ZSL
                            span {{total_ZSL()|HHMM}}
                        td.cell-total-bonuri
                            span {{total_bonuri()}}
                        td -
                        td -
        
        dialog_modificare_timp( ref='dlg_modificare_timp' @save='refresh_info' )
        angajati-dialog(ref='dlg_angajat' @save='refresh_info' )
        //- import-pontaj-dialog(ref='dlg_import' @refresh='refresh_info' )
        //- reparare-timp-dialog(ref='dlg_reparare_timp' @save='refresh_info' )

</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import ModificareTimpDialog from "../dialogs/ModificareTimpDialog";
import AngajatiDialog from "../dialogs/AngajatiDialog";
import moment from 'moment';
import TitluPagina from '../widgets/TitluPagina';
// import ImportPontajDialog from '../dialogs/ImportPontajDialog';
// import ReparareTimpDialog from '../dialogs/ReparareTimpDialog'

export default {
  name: "raport-totalizator",
  extends: BasePage,
  components: {
      'base-page': BasePage,
      'dialog_modificare_timp': ModificareTimpDialog,
      'angajati-dialog': AngajatiDialog,
      'titlu-pagina': TitluPagina,
    //   'import-pontaj-dialog': ImportPontajDialog,
    //   'reparare-timp-dialog': ReparareTimpDialog
  },
  data () {
    return { 
        Filters: { Data: "12-2018", DepartamenteBifate: [] },
        Raport: { Angajati: [] },
        Info: {
            Departamente: []
        },
        selectedEmployeeId: null // Variabila pentru selecția angajatului
    }
  },
  methods: {
    async get_info(){
        var response = await this.post( "raport_totalizator2/get_info" );

        this.Info.Departamente          = response.Departamente;
        this.Filters.DepartamenteBifate = response.DepartamenteBifate;
        this.Filters.Data               = response.Data;
        this.refresh_info();
    },
    async refresh_info() {
        var response = await this.post("raport_totalizator2/raport", { Filters: this.Filters } );
        console.log(response)
        if( response.Eroare )
        {
            this.$message.error( response.MesajEroare );
        }
        else
        {
            this.Raport = response.Raport;
        }
        // this.Raport.Angajati.forEach(angajat => {
        //     var total = 0
        //     // Object.keys(angajat.Zile).forEach(ziKey => {
        //     //     const zi = angajat.Zile[ziKey]
        //     //     total += zi.TimpLucrat
        //     //     console.log(zi.TimpLucrat/60/60)
        //     //     console.log(zi.Zi)
        //     // })
        //     // console.log({zile_angajat: angajat.Zile})
        //     // console.log({TOTAL_ORE: total})
        // })
        this.select_menu_item('raport-totalizator');
    },
    verificaSters(ziDeVerificat, angajat){
        if (angajat.StersDeLaDataDe) {
            var date = new Date(angajat.StersDeLaDataDe)
            var zi = new Date(ziDeVerificat.Zi)

            var ziSters = date.getDate()
            var ziCurenta = zi.getDate()

            var lunaSters = date.getMonth();
            var lunaCurenta = zi.getMonth();

            var anSters = date.getFullYear()
            var anCurent = zi.getFullYear()

            return ziCurenta >= ziSters && lunaCurenta == lunaSters && anCurent == anSters
        } else {
            return false
        }

    },

    verificaAngajatNou(angajat){
        if(parseFloat(angajat.LuniDeCandEAngajat) <= 3){
            return true
        }
        return false
    },

    tooltipForDay(a, zi){
        if( zi.Erori != '') return zi.Erori;
        if( zi.AFostAbsent)return "A fost absent!";
        if( zi.TimpLucratPreaPutin ) return "Timp lucrat prea putin!";

        return '';
    },
    // reparare_perioada(id_angajat){
    //     this.$refs['dlg_reparare_timp'].show_me(id_angajat);
    // },
    async salveaza_observatii(a){
        await this.post("salveaza-observatii-raport-totalizator", { Id: this.Raport.Id, IdAngajat: a.Id, Observatii: a.Observatii } );
        a.IsObservatiiDirty = false;
    },
    // show_import_dlg(){
    //     this.$refs['dlg_import'].show_me();
    // },
    arata_dialog_modificare_timp(angajat, zi){
        //verificam sa nu fie click in viitor sau in ziua de azi
        var date = moment(zi.Zi, 'YYYY-MM-DD');
        var now  = moment();

        if( date.isBefore(now) )
            this.$refs.dlg_modificare_timp.show_me(angajat.Id, zi.Zi);
        else
            this.$message.error( "Nu se poate modifica!" );
    },
    show_angajat_dlg(angajat){
        this.$refs.dlg_angajat.show_me(angajat.Id);
    },
    total_pe_zi(zi){
        var total_ore = 0;

        this.Raport.Angajati.forEach(a => {
            if( a.Zile[zi.FullDate])
                total_ore += parseFloat( a.Zile[zi.FullDate].TimpLucrat );
        });
        return total_ore;
    },
    total_angajati_prezenti(zi){
        var total_prezenti = 0;

        this.Raport.Angajati.forEach(a => {
            if( a.Zile[zi.FullDate]){
                if(a.Zile[zi.FullDate].AFostPrezent == true) total_prezenti ++;
            }
        });
        return total_prezenti;
    },
    total_pe_tip_zi_libera(tzl){
        var prescurtare = tzl.Prescurtare;
        var total_ore = 0;

        this.Raport.Angajati.forEach(a => {
            total_ore += parseFloat( a.TotaluriZileLibere[prescurtare] );
        });
        return total_ore;
    },
    total_ore_lucrate_de_toti(){
        var total_ore = 0;
        this.Raport.Angajati.forEach(a => {
            total_ore += parseFloat( a.TotalOreLucrate );
        });
        return total_ore;
    },
    total_necesar_ore_lucrate(){
        var total_ore_necesare = 0;
        this.Raport.Angajati.forEach(a => {
            // console.log(a)
            total_ore_necesare += parseFloat(a.NecesarOreLucrate)
        });
        return total_ore_necesare
    },
    total_ore_suplimentare(){
        var total_ore_supl = 0;
        this.Raport.Angajati.forEach(a => {
            total_ore_supl += parseFloat(a.OreSuplimentare)
        })
        return total_ore_supl
    },
    total_ore_noapte(){
        var total_ore_noapte = 0;
        this.Raport.Angajati.forEach(a => {
            total_ore_noapte += parseFloat(a.TotalOreNoapte)
        })
        return total_ore_noapte
    },
    total_abateri(){
        var total = 0;
        this.Raport.Angajati.forEach(a => {
            total += parseFloat(a.Abatere)
        })
        return total
    },
    total_ZSL(){
        var total = 0;
        this.Raport.Angajati.forEach(a => {
            total += parseFloat(a.ZSL)
        })
        return total
    },
    total_bonuri(){
        var total = 0;
        this.Raport.Angajati.forEach(a => {
            total += parseFloat(a.NrBonuriMasa)
        })
        return total
    },
    get_excel_href(){
        var newFilters = this.Filters;
        newFilters['token'   ] = settings.get_token();
        newFilters['id_firma'] = settings.get_id_firma();
        var encoded = encodeURIComponent( JSON.stringify( newFilters ) );
        return `${settings.get_base_url()}raport_totalizator2/excel?Filters=${encoded}`;
    },
    get_excel_href_condica(){
        var newFilters = this.Filters;
        newFilters['token'   ] = settings.get_token();
        newFilters['id_firma'] = settings.get_id_firma();
        var encoded = encodeURIComponent( JSON.stringify( newFilters ) );
        return `${settings.get_base_url()}raport_totalizator2/excelCondica?Filters=${encoded}`;
    },
    get_excel_href_condica2(){
        var newFilters = this.Filters;
        newFilters['token'   ] = settings.get_token();
        newFilters['id_firma'] = settings.get_id_firma();
        var encoded = encodeURIComponent( JSON.stringify( newFilters ) );
        return `${settings.get_base_url()}raport_totalizator3/excelCondica?Filters=${encoded}`;
    },
    get_excel_href_condica_rotunjit(){
        var newFilters = this.Filters;
        newFilters['token'   ] = settings.get_token();
        newFilters['id_firma'] = settings.get_id_firma();
        var encoded = encodeURIComponent( JSON.stringify( newFilters ) );
        return `${settings.get_base_url()}raport_totalizator4/excelCondica?Filters=${encoded}`;
    },
    get_excel_href_condica_rotunjit_cu_noapte(){
        var newFilters = this.Filters;
        newFilters['token'   ] = settings.get_token();
        newFilters['id_firma'] = settings.get_id_firma();
        var encoded = encodeURIComponent( JSON.stringify( newFilters ) );
        return `${settings.get_base_url()}raport_totalizator5/excelCondica?Filters=${encoded}`;
    },
    get_nr_oameni_prezenti_astazi(){
        return this.Raport.NrOameniPrezentiAstazi;
    },
  },
  mounted(){
    settings.verify_login_and_redirect(this);

    var container       = document.getElementById('raport-container');
    var filtre          = document.getElementById('filtre');

  }
};
</script>

<style lang="less" scoped>
    textarea:focus, input:focus{
        outline: none;
    }
    .observatii-input{
        margin-top: 3px;
        background: #fff;
        border: 1px solid #dcdfe6;
        border-color: #dcdfe6;
        color: #606266;
        padding: 0px 5px;
    }
    .blinker {
        color: darkorange;
        font-weight: bold;
        animation: blinker 0.5s linear infinite;
    }

    @keyframes blinker {  
        50% { opacity: 0.2; }
    }
    .dirty{
        border-color: red;
        color: darkred;
    }

    .zi-cu-eroare{
        background-color: red !important;
        span{
            color: white !important;
        }
    }
    .angajat-selectat {
        font-weight: bold !important;
        color: #001f3f !important; /* Albastru foarte închis */
        background-color: #B2EBF2 !important; /* O nuanță mai închisă de albastru deschis pentru fundal */
    }

    .raport-container
    {

        @left_column_width: 200px;
        background: white;
        margin-top: 0px;
        width: 100%;
        height: calc( 100vh - 250px);
        overflow: scroll;
        position: relative;

        // table, td, th{
        //     border: 1px solid rgb(43, 43, 43);
        // }

        .raport-table{
            position: relative;
            
            thead {
                tr th{
                    position: -webkit-sticky; /* for Safari */
                    position: sticky;
                    top: 0;
                    background-color: #f7f9fc;
                    width: 50px;
                    min-width: 50px;
                    height: 40px;
                    font-size:12px;
                    text-align:center;
                    z-index:10;
                }
                th:first-child {
                    left: 0;
                    z-index: 1;
                    width: 250px;
                    min-width: 250px;
                    text-align: left;
                    z-index:10;
                }
            }

            tbody{
                overflow: auto;
                
                td{
                    text-align: center;
                    font-size:12px;
                    color: rgb(33, 37, 41);
                    border-right: 1px solid #ECECEC;
                    border-bottom: 1px solid #ECECEC;
                }
                td:first-child {
                    position: -webkit-sticky; /* for Safari */
                    position: sticky;
                    left: 0;
                    text-align: left;
                    overflow: hidden;
                    font-size: 12px;
                    height: 27px;
                    line-height: 27px;
                    text-overflow: none;
                    font-weight: bold;
                    // background-color: white;
                }

                .cell-totaluri{
                    background-color: lightblue;
                }
                .cell-total-ore-lucrate, .cell-ore-suplimentare, .cell-ore-noapte{
                    background-color: black;
                    color: white;
                }
                .cell-detalii{
                    min-width: 200px;
                    font-style: italic;
                    color: black;
                    text-align: left;
                    padding-left: 25px;
                }
            }

            tfoot {
                tr td{
                    position: -webkit-sticky; /* for Safari */
                    position: sticky;
                    bottom: 0;
                    background-color: #f7f9fc;
                    text-align:center;
                    height: 40px;
                    font-size:12px;
                }
                td:first-child {
                    left: 0;
                    z-index: 1;
                    bottom: 0;
                }
            }

            
        }
    }
    
    .force-white-color{
        color: white;
    }
    
    .btn-nume-angajat{
        color: #4a596a !important;
    }
  
    .weekend{
        background-color: lightgray;   
    }
    .zi-libera-national{
        background-color: lighten(lightpink,10%);
    }
    .observatii-raport{
        padding-bottom: 10px;
    }
    .btn-aplica{
        margin-top: 10px;
    }

    tbody td:first-child:not(.este-angajat-nou){
        background-color: white;
    }

    .este-angajat-nou{
        background-color: #e4ffc6;
    }

    

    .el-button--text{
        color: black;
        padding: 0px;
        &:hover{
            color: darkcyan;
        }
    }
    .green-text-button{
        color: #67c23a;
        &:hover{
            color: #67c23a;
        }
    }
    .red-text-button{
        color: red;
        &:hover{
            color: darkred;
        }
    }
    .sters {
        background-color: gray;
    }
    .a-fost-absent{
        background-color: #df414e;
    }
    .alb {
        background-color: white;
    }
  
</style>
